<template>
  <div v-if="isMounted" class="vx-col w-full mb-5" >
    <div style="width:100%; display:flex">
      <h6>{{f.field_name}} </h6>
       <vx-tooltip 
        :title="f.field_name"
        color="primary"
        class="tooltip-align"
        :text="f.indication">
        <vs-chip class="question-mark" color="primary">?</vs-chip>
      </vx-tooltip>
    </div>

    <vs-button color="primary" type="border" icon-pack="feather" class="mb-6 mt-3 mr-3" :disabled="hasBackofficeAccess"  @click="addProduct()">Elegir un modelo</vs-button>
        
      <vs-table stripe :data="pg_requests" noDataText="No se han generado registros">
        <template slot="header">
          <h5 class="mb-2 ml-2">Lista de modelos verificados</h5>
        </template>

        <template slot="thead">
          <vs-th class="bold">MODELO</vs-th>
          <vs-th class="bold">MARCA</vs-th>
          <vs-th class="bold">FAMILIA DE PRODUCTOS</vs-th>
          <vs-th class="bold">CANTIDAD DEFINIDA</vs-th>
          <vs-th class="bold" v-if="!hasBackofficeAccess">ACCIONES</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].product_model_id">
              {{ data[indextr].model }}
            </vs-td>

            <vs-td :data="data[indextr].product_brand_id">
              {{ data[indextr].brand }}
            </vs-td>

            <vs-td :data="data[indextr].product_group_id">
              {{ data[indextr].product_group_name }}
            </vs-td>

            <vs-td :data="data[indextr].quantity">
              {{ data[indextr].quantity }}
            </vs-td>

            <vs-td :data="data[indextr].id" v-if="!hasBackofficeAccess">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-warning stroke-current cursor-pointer" class="mr-3" @click.stop="editItemRequest(data[indextr])" />
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-warning stroke-current cursor-pointer" class="ml-3" @click.stop="removeItemRequest(data[indextr])" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup
        :title="onEditMode ? 'Modificar cantidad de producto' : 'Elegir modelo'"
        :active.sync="showFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{formPromptIndication}}</p>

           <vs-input disabled
              class="w-full mb-5"
              v-model="fg_name"
              label="Sistema o tecnología" />
            
            <vs-input disabled
              v-if="onEditMode"
              class="w-full mb-5"
              v-model="product_group_name"
              label="Grupo de productos" />

            <vs-input disabled
              v-if="onEditMode"
              class="w-full mb-5"
              v-model="brand_name"
              label="Marca" />

            <vs-input disabled
              v-if="onEditMode"
              class="w-full mb-5"
              v-model="model_name"
              label="Modelo" />

          <label for="" class="vs-input--label">Grupo de productos</label>
          <v-select
            v-if="!onEditMode"
            label="text"
            name="product_group_id"
            class="vs-custom w-full mb-5"
            v-model.lazy="product_group_id"
            :options="getModalProductGroups"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="true"
            :placeholder="getModalProductGroups.length < 1 ? noDataPlaceholder : selectPlaceholder"
            :disabled="family_group_id == null || getModalProductGroups.length < 1"
          >
          <div slot="no-options">No hay coincidencias</div>
          </v-select>

          <label for="" class="vs-input--label">Marca</label>
          <v-select
            v-if="!onEditMode"
            label="text"
            name="brand_id"
            class="vs-custom w-full mb-5"
            v-model.lazy="brand_id"
            :options="getModalProductBrands"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="true"
            :placeholder="getModalProductBrands.length < 1 ? noDataPlaceholder : selectPlaceholder"
            :disabled="getModalProductBrands.length < 1"
          >
          <div slot="no-options">No hay coincidencias</div>
          </v-select>

          <label for="" class="vs-input--label">Modelo</label>
          <v-select
            v-if="!onEditMode"
            label="text"
            name="product_model_id"
            class="vs-custom w-full mb-5"
            v-model.lazy="product_model_id"
            :options="getModalProductModels"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="true"
            :placeholder="getModalProductModels.length < 1 ? noDataPlaceholder : selectPlaceholder"
            :disabled="brand_id == null || getModalProductModels.length < 1"
          >
          <div slot="no-options">No hay coincidencias</div>
          </v-select>

          <vs-input
            class="w-full mb-4" 
            label="Cantidad"
            placeholder="Requerido"
            :min="1"
            type="number"
            v-model.lazy="quantity" />  

          <vs-button :disabled="!completeForm || $isLoading" @click="saveProductRequest" color="primary" class="mr-5 mt-2">{{ onEditMode ? 'Actualizar':'Agregar' }}</vs-button>
          <vs-button @click="closeFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>

    </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper"
const indicationDef = "Ingresa los datos solicitados.";
const errorMssgDef = "Ha ocurrido un error al agregar el producto, intente más tarde."
const duplicatedError = "Ya has registrado este modelo previamente."
const successMssgDef = "El producto ha sido agregado exitosamente"

export default {
  name: 'pg-request-control',
  mixins: [inputHelper],
  props: {
    f: { type: Object, required: true },
    family_group_id: { type: [Number,String], required: true },
    family_group_name: { type: [String], required: true },
    project_fg_location_id: { type: [Number,String], required: true },
  },
  data(){
    return {
      product_model_id: null,
      quantity: null,

      productGroupsList: [],
      brandsList: [],
      modelsList: [],

      getProductGroupsForSelect: [],
      getProductBrandsForSelect: [],
      getModelsForSelect: [],
      pg_requests: [],

      product_group_id: null,
      brand_id: null,

      showFormPrompt: false,
      selected_pg_request: null,
      fg_name: null,
      product_group_name: null,
      brand_name: null,
      model_name: null,

      onError: false,
      onSuccess: false,
      errorMssg: errorMssgDef,
      successMssg: successMssgDef,
      formPromptIndication: indicationDef,

      selectPlaceholder: "Seleccione una opción",
      noDataPlaceholder: "No hay opciones disponibles",

      onEditMode: false,
      isMounted: false,
    }
  },
  created(){
    this.fg_name = this.$props.family_group_name;
  },
  mounted: async function(){
    this.isMounted = false;
    await this.getAvailableProducts();
    await this.loadSavedPgRequests();
    this.isMounted = true;
  },
  computed: {
    getModalProductGroups() {
      this.product_group_id = null;
      let collection = this.getProductGroupsForSelect.filter((productGroup) => productGroup.family_group_id == this.$props.family_group_id);
			return collection.map((productGroup) => ({ value: productGroup.id, text: productGroup.name }));
    },
    getModalProductBrands() {
			this.brand_id = null;
			let collection = this.getProductBrandsForSelect.filter((brand) => brand.product_group_id == this.product_group_id);
			return collection.map((brand) => ({ value: brand.id, text: brand.brand }));
		},
    getModalProductModels() {
			this.product_model_id = null;
			let collection = this.getModelsForSelect.filter((model) => model.product_brand_id == this.brand_id);
			return collection.map((model) => ({ value: model.id, text: model.model }));
		},
    completeForm(){
      return this.product_model_id != null && this.quantity != null;
    },
    hasProducts(){
      return this.modelsList.length > 0;
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    isPhotovoltaic(){
      return this.family_group_id == 1;
    }
  },
  methods: {
    async loadSavedPgRequests(){
      try {
        let res = await axios.get(`/api/v1/fg_locations/${this.$props.project_fg_location_id}/getPgRequests`);
        this.pg_requests = res.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    // async getAvailableProducts(){
    //   try {
    //     this.productGroupsList = [];
    //     this.getProductGroupsForSelect = [];
    //     let res_brands = await axios.get(`api/v1/supplier/${this.SupplierId}/familyGroup/${this.$props.family_group_id}/getFgSupplierProductBrands`);
    //     this.brandsList = res_brands.data;
    //     this.brandsList.forEach(opt => {
    //       if(this.productGroupsList.find(f => f.id == opt.product_group_id) == null){
    //         this.productGroupsList.push({id: opt.product_group_id, name: opt.product_group_name});
    //         this.getProductGroupsForSelect.push({value: opt.product_group_id, text: opt.product_group_name}); //conversion a sintaxis de vx-select 
    //       }
    //     })

    //     let res_models = await axios.get(`api/v1/supplier/${this.SupplierId}/familyGroup/${this.$props.family_group_id}/getFgSupplierProductModels`);
    //     this.modelsList = res_models.data;
    //   }
    //   catch (e) {
    //     this.warn(e);
    //     this.failed = true;
    //   }
    // },
    async getAvailableProducts() {
      try {
        let {data} = await axios.get('/api/v1/forms/getProductGroups');
        if(data) 
          this.getProductGroupsForSelect = data;

        ({data} = await axios.get('/api/v1/supplier/getProductBrands'));
        if(data) 
          this.getProductBrandsForSelect = data;

        ({data} = await axios.get('/api/v1/supplier/models'));
        if(data) 
          this.getModelsForSelect = data;
      }
      catch (error) {
        console.log(error);
        this.warn(error);
        this.failed = true;	
      }
    },
    removeItemRequest(pg){
      this.selected_pg_request = pg;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar producto',
        text: `¿Realmente desea eliminar el producto ${pg.model}?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveItem
      });
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {pg_request_id: this.selected_pg_request.id};
        await axios.post(`/api/v1/fg_locations/removePgRequest`, payload);
        await this.loadSavedPgRequests();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    async saveProductRequest(){
      if(this.onEditMode){
        await this.updateProduct();
      }
      else {
        await this.saveProduct();
      }
    },

    async saveProduct(){
      this.onSuccess = false;
      this.onError = false;

      // validar que no se haya registrado el mismo modelo
      if(this.pg_requests.find(f => f.product_model_id == this.product_model_id)){
        this.onError = true;
        this.errorMssg = duplicatedError;
        return;
      }
      
      this.showLoading(true);
      try {
        let payload = { quantity: this.quantity, product_model_id: this.product_model_id};  
        await axios.post(`/api/v1/fg_locations/${this.$props.project_fg_location_id}/addPgRequest`, payload);
        this.onSuccess = true;
        await this.loadSavedPgRequests();
        this.closeFormPrompt();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },

    async updateProduct(){
      this.onSuccess = false;
      this.onError = false;
      this.showLoading(true);

      try {
        let payload = { quantity: this.quantity, pg_request_id: this.selected_pg_request.id};
        await axios.post(`/api/v1/fg_locations/updatePgRequest`, payload);
        this.onSuccess = true;
        await this.loadSavedPgRequests();
        this.closeFormPrompt();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },

    addProduct(){
      this.resetForm();
      this.showFormPrompt = true;
    },
    editItemRequest(item){
      this.resetForm();
      this.product_model_id = item.product_model_id;
      this.quantity = item.quantity; 
      this.selected_pg_request = item;
      this.product_group_name = item.product_group_name;
      this.brand_name = item.brand;
      this.model_name = item.model;
      this.onEditMode = true;
      this.showFormPrompt = true;
    },
    resetForm(){
      this.onError = null
      this.onSuccess = null
      this.onEditMode = false
      this.product_model_id = null;
      this.product_group_id = null;
      this.brand_id = null;
      this.quantity = null; 
    },
    closeFormPrompt() {
      this.showFormPrompt = false;
      this.resetForm();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },

    /* check validation */
    validate(){
      return this.pg_requests.length >= 1;
    }
  }
}
</script>

<style>
.question-mark {
  padding-right: 3px !important;
}
</style>
