<template>
  <div class="vx-col w-full mb-4" v-if="isMounted">
    <div class="vx-col w-full mb-5">
      <vs-alert v-if="cfe_days > penalty_days" class="h-full" color="warning">
        <span>El <strong>inicio de tramite de interconexión con CFE</strong> ha superado los <strong>30 días</strong> de diferencia con la 
          <strong>fecha de pago de anticipo</strong> y puedes ser penalizado.</span>
      </vs-alert>
    </div>
    <div class="vx-row">
      <template v-for="f in fgFormFields">
      <form-field 
        ref="form_fields"
        :key="f.id"
        :f="f"
        :dataContainer="fgReqsContainer"
        :onChangeReceptor="onFieldChange"
        :keepDisabled="isDisabled"
        :blockedByDocument="blockedByDocument"
        :evaluator="evaluateCondition" />
      </template>
    </div>
  </div>
</template>
<script>
import FormField from '@components/forms/FormField.vue'

const penalty_days = 30;

export default {
  name: 'family_group_reqs',
  props: {
    section_id: { type: [Number,String], required: true },
    family_group_id: { type: [Number,String], required: true },
    project_fg_location_id: { type: [Number,String], required: true },
    blockedByDocument: { type: Boolean, default: false },
    isDisabled: { type: [String,Boolean], default: false },
    cfeServicesQuantity: { type: [Number], default: 1}
  },
  components: { FormField },
  data(){
    return {
      fgFormFields: [],
      fgReqsContainer: {},
      fgReqsList: [],
      fgLocationReqsList: [],
      isMounted: false,
      cfe_days: 0,
      penalty_days: penalty_days
    }
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getFgReqsDefinitions();
    await this.getProjectFgLocationReqs();
    await this.validateInterconnectionWithCFE();
    this.setFormFields();
    this.isMounted = true;
    this.showLoading(false);
  },
  methods: {
    async getFgReqsDefinitions(){
      try {
        let res1 = await axios.get(`api/v1/forms/getFgReqs/familyGroup/${this.$props.family_group_id}/section/${this.$props.section_id}`);
        this.fgReqsList = res1.data;  
      }
      catch (error) {
        this.warn(error);
      }
    },
    async getProjectFgLocationReqs(){
      try {
        let res = await axios.get(`/api/v1/fg_locations/${this.project_fg_location_id}/getFgReqsValues`);
        this.fgLocationReqsList = res.data;
        this.fgLocationReqsList.forEach(req => {
          let fg_name = 'fg_' + req.fg_reqs_id;
          let fg = this.fgReqsList.find(f => f.id == req.fg_reqs_id);
          if(fg){
            if(!this.fgReqsContainer[fg_name])
              this.fgReqsContainer[fg_name] = this.translateDataTypes(fg.field_type,req.value);
          }
        })
      } catch (error) {
        this.warn(error);
      }
    },
    translateDataTypes(type, value){
      if(type == 'checkbox'){
        return (value == "1" || value == "true");
      }
      else if(type == 'select'){
        return parseInt(value);
      }
      return value;
    },
    setFormFields(){
      this.fgReqsList.forEach(fg => {
        this.fgFormFields.push(this.formFieldFormFgReq(fg));
      });
    },
    formFieldFormFgReq(fg){
      let extras = null;
      let required = true;
      let max = 0;
      let div_class = null;
      let indication = null;
      let show_only_if = null;
      if(fg.extras != null){
        extras = JSON.parse(fg.extras);
        if(extras.is_required != null){
          required = extras.is_required == 1;
        }      
        max = extras.max ? extras.max : 0;

        // check prefix for div_class key
        if(extras.div_class != null){
          div_class = extras.div_class;
        }

        if(extras.indication != null){
          indication = extras.indication;
        }

        if(extras.show_only_if != null){
          show_only_if = extras.show_only_if;
        }
      }

      let fg_name = 'fg_' + fg.id;
      let f = {
        id: fg.id,
        field_name: fg.requirement,
        fname: fg_name,
        order: fg.order,
        db_field: fg_name,
        db_table: "fg_reqs",
        field_type: fg.field_type,
        is_required: required,
        collection: fg.possible_values,
        bo_private: 0,
        special_definition: 0,
      };

      if(max != 0){
        f.min_chars = 1;
        f.max_chars = max;
      }

      if(div_class){
        f.div_class = div_class;
      }

      if(indication){
        f.indication = indication;
      }
      
      if(show_only_if){
        f.show_conditioned = true;
        f.condition = show_only_if;
        f.parsed_extras = {
          "show_only_if":show_only_if 
        }

      }
      return f;
    },
    async checkValidation(){
      let allValid = true;
      let grefs = this.$refs['form_fields'];
      let f;
      for(let p in grefs){
        f = grefs[p];
        let r = await f.checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
      }
      return allValid;
    },
    setError(mssg){
      this.specialError = mssg;
    },
    specialValidation(){      
      return this.f.special_validation;
    },

    // llamado por el objeto padre
    async saveFgReqs(){
      let reqs = [];
      this.fgReqsList.forEach(fg => {
        let fg_name = 'fg_' + fg.id;
        let item = {fg_reqs_id: fg.id, value: this.fgReqsContainer[fg_name]};
        let ff = this.fgLocationReqsList.find(f => f.fg_reqs_id == fg.id);
        if(ff){
          item.id = ff.id;
        }
        reqs.push(item);
      });

      let payload = {fg_reqs: reqs};
      try {
        await axios.post(`/api/v1/fg_locations/${this.project_fg_location_id}/saveFgReqsValues`, payload);
        await this.validateInterconnectionWithCFE();
        return "ok";
      }
      catch (error) {
        this.warn(error);
        return "error";
      }
    },

    /* evaluator */
    evaluateCondition(condition){
      if(condition != null && condition in this){
        let ss = this[condition];
        return ss;
      }      
    },
    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },

    async validateInterconnectionWithCFE(){
      try {
        let res = await axios.get(`/api/v1/fg_locations/${this.project_fg_location_id}/validateCFE`);
        this.cfe_days = res.data;
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    should_fill_second_service(){
      return this.cfeServicesQuantity >= 2;
    },
    should_fill_third_service(){
      return this.cfeServicesQuantity >= 3;
    },
    should_fill_fourth_service(){
      return this.cfeServicesQuantity >= 4;
    },
    should_fill_fifth_service(){
      return this.cfeServicesQuantity >= 5;
    },
  }
}
</script>