<template>
  <div :class="{'p-6': hasBackofficeAccess}">
    <div class="vx-row" v-if="hasBackofficeAccess">
      <div class="vx-col w-full mb-3">
        <router-link
          class="decorated"
          :to="`/project/${id}`"
        >Regresar al proyecto </router-link>
      </div>
    </div>

    <big-title v-if="isMounted">
      {{project.alias || "Pendiente" }} -<span> {{id}}.</span>
      <h5 class="mt-2">Editar detalles de instalación para tecnología - {{familyGroupName}}</h5>
      <p class="text-subtitle mt-6">Agrega la dirección y datos técnicos para la tecnología o sistema definido, de esta instalación.</p>
    </big-title>

    <!-- SECCIONES -->

    <div v-if="isMounted && !failed" >

      <vx-card v-for="section in allSections" :key="section.id" :title="section.public_name" class="mb-5">
        <p class="mb-4" v-if="section.public_description != null">{{section.public_description}}</p>

        <div class="vx-row">

           <div class="vx-col w-full mb-6" v-if="section.id == 37">
            <vs-alert style="min-height: 86px !important;" color="primary" v-if="payload.address_source === 2">
              <span>
                En caso de que elijas el domicilio del solicitante como la ubicación del proyecto, deberás asegurarte que la dirección registrada
                anteriormente en la sección <strong>Domicilio del solicitante</strong> sea igual a la del documento 
                predial/escritura que vayas a compartir a continuación. En caso de que no sea igual, contacta a tu ejecutivo de cuenta para solicitar
                un ajuste. 
              </span>
            </vs-alert>
            <vs-alert style="min-height: 86px !important;" color="primary" v-else-if="payload.address_source === 3">
              <span>
                Es de suma Importancia que la dirección que vayas a registrar como ubicación del proyecto sea la misma que la del documento predial/escritura 
                que ingreses a continuación, ya que de lo contrario no podremos aprobar la solicitud.
              </span>
            </vs-alert>
          </div>

          <div class="vx-col w-full mb-6" v-if="section.id == 37 && locationDefined">
            <p><strong>Dirección definida:<br></strong> {{fullLocation}}</p>
            <!-- BOTÓN PARA EDITAR DIRECCIÓN PROPIA DE UBICACIÓN -->
            <div class="vx-row" v-if="(section.id == 37) && !isDisabled && payload.address_source === 3">
              <div class="vx-col w-full">
                <div class="flex flex-wrap items-center justify-end">
                  <vs-button color="success" class="ml-auto mt-2" @click="setAddressToEdit()">Editar dirección</vs-button>
                </div>
              </div>
            </div>
            <vs-divider></vs-divider>
          </div >

          <vs-alert class="mb-4 ml-4 mr-4" color="primary" v-if="section.id == 37 && locationDefined && show_other_document_type_message">
            <span>
              Es posible que RedGirasol no acepte el documento de propiedad que subas como <b>OTRO</b> documento.
              Te recomendamos que selecciones y compartas un documento que se encuentre dentro de nuestro catálogo.
            </span>
          </vs-alert>

          <template v-for="f in section.fields">
            <!-- Campos automaticos -->
            <form-field
              :ref="'section' + section.id"
              v-if="isAutoFormField(f)"
              :key="f.id"
              :f="f"
              :dataContainer="payload"
              :keepDisabled="isDisabled"
              :collectionsRoot="collections"
              :blockedByDocument="f.blockedByDocument"
              :onChangeReceptor="onFieldChange"
              :hasBackofficeAccess="hasBackofficeAccess"
              :evaluator="evaluateCondition" />

            <!-- fg_reqs -->
            <family-group-reqs
              :key="f.id"
              :section_id="section.id"
              :family_group_id="familyGroupId"
              :project_fg_location_id="fg_id"
              :isDisabled="isDisabled"
              :blockedByDocument="f.blockedByDocument"
              :cfeServicesQuantity="payload.location$cfe_services_quantity"
              ref="family_group_reqs"
              v-else-if="f.fname == 'family_group_reqs'" />

            <!-- pg_request -->
            <pg-request-control
              :key="f.id"
              :f="f"
              :family_group_id="familyGroupId"
              :family_group_name="familyGroupName"
              :project_fg_location_id="fg_id"
              :isDisabled="isDisabled"
              :blockedByDocument="f.blockedByDocument"
              ref="pg_request_table"
              v-else-if="f.fname == 'pg_request_table'"
              :SupplierId="project.supplier_id" />

            <div class="vx-col w-full mb-3"
                 v-else-if="f.fname == 'select_address_source' && !locationDefined && !addressEditFlag"
                 :key="f.id" >
              <span class="bold">{{f.field_name}}</span>
              <br/><br/>
              <ul class="leftx">
                <li class="mb-3" :key="item.id" :value="item.id" :text="item.label" v-for="(item) in validAddressSelectOptions">
                  <vs-radio :disabled="isDisabled" v-model="payload.address_source" vs-name="address_source" :vs-value="item.id">{{item.label}} <span class="alt-radio-text">{{altRadioText(item.id)}}</span> </vs-radio>
                </li>
              </ul>
            </div>

          </template>
        </div>

        <!-- Save Button -->
        <div class="vx-row" v-if="(section.id == 38 || section.id == 57) && !isDisabled">
          <div class="vx-col w-full">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button class="ml-auto mt-2" @click="saveTechnologyData(section)">Guardar cambios</vs-button>
            </div>
          </div>
        </div>

        <div class="vx-row" v-if="section.id == 37 && !isDisabled">
          <div class="vx-col w-full">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button class="ml-auto mt-2" @click="saveLocation(section)">{{location_is_defined ? 'Guardar cambios' : 'Continuar'}}</vs-button>
            </div>
          </div>
        </div>

        <template v-if="section.documentFields.length > 0 && section.id != 37">
          <vs-divider></vs-divider>
          <h6 class="mb-3">Documentos</h6>

          <div class="vx-row">
            <document-upload
              v-for="df in section.documentFields"
              :key="df.id"
              :target_id="df.target_id"
              :f="df.formField"
              :doc="df.documentSet"
              :loadedState="df.loadedState"
              :disabled="docsDisabled"
              :stickToLoadedState="true"
              :evaluator="evaluateCondition"
              :from_project_id="id"
              :editableByBackoffice="true"
              @on-success="onDocumentUploadSuccess"
            />
          </div>
        </template>

        <!--<template v-if="section.id == 37 && location_is_defined">
          <vs-divider></vs-divider>
          <h6 class="mb-3">Documentos</h6>

          <div class="vx-row">
            <document-upload
              v-for="df in section.documentFields"
              :key="df.id"
              :target_id="df.target_id"
              :f="df.formField"
              :doc="df.documentSet"
              :loadedState="df.loadedState"
              :disabled="docsDisabled"
              :stickToLoadedState="true"
              :evaluator="evaluateCondition"
              :from_project_id="id"
              :editableByBackoffice="true"
              @on-success="onDocumentUploadSuccess"
            />
          </div>
        </template>-->

      </vx-card>

    </div>

    <load-error v-if="failed" />

  </div>
</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import docsHelper from '@components/mixins/docsHelper';
import FormField from '@components/forms/FormField.vue';
import FamilyGroupReqs from '@components/forms/FamilyGroupReqs.vue';
import PgRequestControl from '@technologies/PgRequestControl.vue';

const addressSelectDefinitions = [
  // { id: 1, label: "Misma dirección del cliente", valid: true },
  { id: 2, label: "Elegir una dirección relacionada con el proyecto", valid: true },
  { id: 3, label: "Agregar otra dirección", valid: true },
];
const verifiedEnumValue = "verificado";
export default {
  props: ['id', 'fg_id'],
  mixins: [formHelper, inputHelper, dateHelper, docsHelper],
  data(){
    return {
      errorCode: 0,
      onError: false,
      metaData: {},
      project: {},
      fgLocation: {},
      form_id: 19,

      form: {},
      allSections: [],

      mexicoCountryId: 700,

      loading: false,
      isMounted: false,
      isRefreshing: false,
      errorMssg: '',
      successMssg: '',
      failed: false,

      documentsFields: [],
      documentsStates: [],
      familyGroupName: null,
      allAddresses: [],
      familyGroupId: null,

      payload: {},

      collections: {
        existingLocations: [],
        countries: [],
        cities: [],
        family_groups: [],
        relationTypes: [],

        states_supplier_workplan_address: [],
        cities_supplier_workplan_address: [],
        neighborhoods_supplier_workplan_address: []
      },

      addressSelectOptions: addressSelectDefinitions,
      locationDefined: false,
      fullLocation: null,

      clientAddress: null,
      location_id: null,

      validatedFields: [],
      validatedDocuments: [],
      allDocumentFields: [],
      addressEditFlag: false,
      addressPayload: {}
    }
  },
  components: {
    FormField,
    FamilyGroupReqs,
    PgRequestControl,
  },
  async mounted() {
    this.loading = true;
    this.isMounted = false;
    await this.getCollections();
    await this.getProjectInfo();
    await this.getFormInfo();
    this.setSectionGroups();
    this.setBlockedDocuments();
    this.isMounted = true;
    this.loading = false;
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
        this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    },
  },
  computed: {
    isArchived(){
      return this.project.resolution_date !== null;
    },
    isDisabled(){
      return this.isArchived || this.project.status >= 4 || this.hasBackofficeAccess;
    },
    docsDisabled(){
      return this.isArchived || this.project.status >= 4;
    },
    validAddressSelectOptions(){
      return this.addressSelectOptions.filter(e => e.valid);
    },
    should_fill_new_location(){
      return this.payload.address_source == 3 && !this.locationDefined;
    },
    should_fill_not_owner_info(){
      return this.payload.location$ownership != true && this.location_id != null;
    },
    should_fill_not_owner_info_and_client_owns_another(){
      return this.should_fill_not_owner_info && this.payload.location$other_ownership == 1;
    },
    should_select_existing_location(){
      return this.payload.address_source == 2 && !this.locationDefined;
    },

    pg_location_exists(){
      return true;
    },
    project_fg_location_id(){
      return this.$props.fg_id;
    },

    location_exists(){
      return this.location_id != null;
    },

    location_is_defined(){
      return this.location_id != null;
    },
    location_is_defined_and_ownership(){
      return this.location_id != null && this.payload.location$ownership == true
    },
    location_is_defined_and_is_other(){
      return this.location_is_defined && this.payload.location$ownership_document_type === 7;
    },
    should_fill_not_owner_info_and_is_other(){
      return this.should_fill_not_owner_info_and_client_owns_another && this.payload.location$other_ownership_document_type === 7;
    },
    show_other_document_type_message(){
      return this.location_is_defined_and_is_other || this.should_fill_not_owner_info_and_is_other;
    },

    /* validaciones por family_group para fg_reqs */
    should_fill_photovoltaic_system_reqs(){
      return this.familyGroupId == 1;
    },
    should_fill_solar_heater_system_reqs(){
      return this.familyGroupId == 2;
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    is_nomenclature_needed(){
      return this.payload.location$street_nomenclature_needed;
    }, 
    are_more_than_one_cfe_service(){
      return this.payload.location$cfe_services_quantity > 1;
    },
    should_fill_new_services_quantity(){
      return this.location_is_defined && this.are_more_than_one_cfe_service && this.payload.location$are_cfe_services_unified;
    },
    should_fill_second_service(){
      return this.payload.location$cfe_services_quantity >= 2;
    },
    should_fill_third_service(){
      return this.payload.location$cfe_services_quantity >= 3;
    },
    should_fill_fourth_service(){
      return this.payload.location$cfe_services_quantity >= 4; 
    },
    should_fill_fifth_service(){
      return this.payload.location$cfe_services_quantity >= 5;
    },
    should_fill_business_name(){
      return (this.payload.location$type == 2 || this.payload.location$type == 3);
    }
  },
  methods: {
    altRadioText(item_id){
      if(item_id == 1){
        let ad = null;
        if(this.clientAddress != null){
          ad = this.clientAddress.full_address;
        }
        ad = (ad == null || ad == "") ? "Sin datos" : ad;
        return `(${ad})`;
      }
    },
    async getRequestInfo(){
      let famGroup = this.collections.family_groups.find(f => f.id == this.fam_id);
      if(famGroup == null){
        this.failed = true;
      }
      else {
        this.familyGroupName = famGroup.name;
      }
    },
    async getProjectInfo() {
      try {
        this.onError = false;
        let params = "with[]=projectFgLocations.familyGroup&with[]=projectFgLocations.location.address&with[]=client.user.personal.address";
        let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
        if(!response.data){
          this.abort();
          return;
        }

        this.project = response.data;
        this.clientAddress = this.nestedFieldValue(this.project, 'client.user.personal.address');
        this.clientAddressId = this.nestedFieldValue(this.project, 'client.user.personal.address.id');

        if(!response.data.project_fg_locations){
          this.abort();
          return;
        }

        this.fgLocation = response.data.project_fg_locations.find(f => f.id == this.fg_id);

        if(!this.fgLocation){
          this.abort();
          return;
        }

        this.familyGroupName = this.fgLocation.family_group.name;
        this.familyGroupId = this.fgLocation.family_group.id;

        this.location_id = this.fgLocation.location_id;

        let loc = this.nestedFieldValue(this.fgLocation, 'location');
        let add = this.nestedFieldValue(this.fgLocation, 'location.address');

        if(loc && add){
          this.payload = {
            location$owner_relation_type: loc.owner_relation_type,
            location$ownership: loc.ownership == 1 ? true : false,
            location$renting_time: loc.renting_time,
            location$other_ownership: loc.other_ownership,
            location$street_nomenclature_needed: loc.street_nomenclature_needed,
            location$cfe_services_quantity: loc.cfe_services_quantity,
            location$are_cfe_services_unified: loc.are_cfe_services_unified,
            location$cfe_unified_services_quantity: loc.cfe_unified_services_quantity,
            location$ownership_document_type: loc.ownership_document_type,
            location$ownership_document_type_other: loc.ownership_document_type_other,
            location$other_ownership_document_type: loc.other_ownership_document_type,
            location$other_ownership_document_type_other: loc.other_ownership_document_type_other,
            location$type: this.translateLocationType(loc.type),
            location$name: loc.name,
          };

          this.fullLocation = add.full_address;
          this.locationDefined = true;

          if(add.id != this.clientAddressId){
            this.addressPayload = add;
            this.payload.address_source = 3;
          } else {
            this.payload.address_source = 2;
          }
          this.reload_cities();
        }
        else {
          this.payload = {
            location$ownership: false,
          };
        }

        this.payload.supplier_workplan = this.fgLocation.supplier_workplan;

        this.existingLocations = [];
        let resAddresses = await axios.get(`/api/v1/supplier/project/${this.id}/getAddressesList`);
        this.allAddresses = resAddresses.data;

        this.allAddresses.forEach(ad => {
          this.collections.existingLocations.push({id: ad.id, text: ad.full_address});
        });
      }
      catch (error) {
        this.processError(error.status);
      }
    },
    translateLocationType(value) {
      if (value) {
        if (value == "home") {
          return 1;
        } else if (value == "business") {
          return 2;
        } else {
          return 3;
        }
      }
      return null;
    },
    async reloadLocationInfo() {
      try {
        this.onError = false;
        let params = "with[]=projectFgLocations.familyGroup&with[]=projectFgLocations.location.address&with[]=client.user.personal.address";
        let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
        if(!response.data){
          this.abort();
          return;
        }

        let project = response.data;
        this.clientAddress = this.nestedFieldValue(project, 'client.user.personal.address');
        this.clientAddressId = this.nestedFieldValue(project, 'client.user.personal.address.id');

        if(!response.data.project_fg_locations){
          this.abort();
          return;
        }

        this.fgLocation = response.data.project_fg_locations.find(f => f.id == this.fg_id);

        if(!this.fgLocation){
          this.abort();
          return;
        }

        this.familyGroupName = this.fgLocation.family_group.name;
        this.familyGroupId = this.fgLocation.family_group.id;

        this.location_id = this.fgLocation.location_id;

        let loc = this.nestedFieldValue(this.fgLocation, 'location');
        let add = this.nestedFieldValue(this.fgLocation, 'location.address');

        if(loc && add){
          this.payload = {
            location$owner_relation_type: loc.owner_relation_type,
            location$ownership: loc.ownership == 1 ? true : false,
            location$renting_time: loc.renting_time,
            location$other_ownership: loc.other_ownership,
            location$street_nomenclature_needed: loc.street_nomenclature_needed,
          };
          this.fullLocation = add.full_address;
          this.locationDefined = true;

          if(add.id != this.clientAddressId){
            this.addressPayload = add;
            this.payload.address_source = 3;
          } else {
            this.payload.address_source = 2;
          }
          this.reload_cities();
        }
        else {
          this.payload = {
            location$ownership: false,
          };
        }

        this.existingLocations = [];
        let resAddresses = await axios.get(`/api/v1/supplier/project/${this.id}/getAddressesList`);
        this.allAddresses = resAddresses.data;

        this.allAddresses.forEach(ad => {
          this.collections.existingLocations.push({id: ad.id, text: ad.full_address});
        });
        this.addressEditFlag = false;
      }
      catch (error) {
        this.processError(error.status);
      }
    },
    async getFormInfo(){
      try{
        let response = await axios.get("/api/v1/forms/getFormFields/" + this.form_id);
        this.form = response.data.form;
        this.allSections = response.data.sections;
        let url = this.ApiDomain + `/storagev3/documents/form/${this.form_id}`;
        let response2 = await axios.get(url);
        this.documentsStates = response2.data;

        this.allDocumentFields = [];

        await this.asyncForEach(this.allSections, async (s) => {
          s.documentFields = [];
          await this.asyncForEach(s.fields, async (f) => {
            this.formFieldSetter(f, this, true);

            // check for documents
            if(f.doc_id != null){
              let ff = {formField: f, documentSet: null, target_id: null };
              let ss = this.documentsStates.find(sid => sid.id == s.id);
              if(ss && ss.documents){
                let dset = ss.documents.find(d => d.id == f.doc_id);
                if(dset){
                  ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                  ff.documentSet = dset;
                }
              }
              s.documentFields.push(ff);
              this.allDocumentFields.push(ff);
            }
          });
        });
        let res = await this.getAllDocumentsStates(this.allDocumentFields, this.evaluateCondition);
        if(res){
          this.allDocumentFields.forEach(f => {
            if(f.loadedState && f.loadedState.is_verified == verifiedEnumValue){
              this.validatedDocuments.push(f.documentSet.id);
            }
          })
        }
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    onDocumentUploadSuccess(data){
      let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
      d.loadedState = data;
    },
    setBlockedDocuments(){
      this.validatedDocuments.forEach(doc => {
        this.allSections.forEach(section => {
          section.fields.filter(f => f.block_by_document_id == doc).forEach(fd => fd.blockedByDocument = true);
        })
      })
    },
    abort(){
      this.loading = false;
      this.$router.replace({name: 'inicio'});
    },
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList', 'statesList', 'familyGroups', 'ownerRelationTypes'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
        this.collections.countries = res.data.countriesList;
        this.collections.states = res.data.statesList;
        this.collections.family_groups = res.data.familyGroups;
        this.collections.relationTypes = res.data.ownerRelationTypes;
      }
      catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },

    async saveTechnologyData(section) {
      let result = await this.runValidations(section);
      if(!result){
        this.missingFieldsNotif();
      }
      else{
        this.loading = true;
        let res = await this.saveFormInfo(section);
        this.loading = false;

        if(res == true){
          this.saveSuccessNotif();
        }
        else {
          this.failedOperationNotif(null, null);
        }
      }
    },

    async saveLocation(section) {
      let result = await this.runValidations(section);
      if(!result){
        this.missingFieldsNotif();
      }
      else{
        this.loading = true;
        if(this.location_is_defined){
          let res = await this.saveFormInfo(section);

          if(res == true){
            this.saveSuccessNotif();
          }
          else {
            this.failedOperationNotif(null, null);
          }
        }
        else {
          try {
            let payload = this.packNested(this.payload);

            if(this.payload.address_source == "3"){ await axios.post(`/api/v1/fg_locations/${this.fg_id}/setFgLocationNewAddress`, payload); } // guardar nueva direccion
            else { await axios.post(`/api/v1/fg_locations/${this.fg_id}/setFgLocationFromAddress`, payload); } // relacionar con direccion existente
            await this.reloadLocationInfo();
            this.saveSuccessNotif();
          }
          catch (error) {
            this.warn(error);
            this.failedOperationNotif(null, null);
          }
        }
        this.loading = false;
      }
    },

    async runValidations(section) {
      // validar radio
      if(section.id == 37 && this.payload.address_source == null && this.location_id == null){
        this.missingFieldsNotif("Seleccione la ubicación", "Debes elegir la ubicación de esta instalación", 6000);
        return false;
      }
      let gslug = "section" + section.id;

      let res1 = await this.validateFormFields(gslug);
      if(!res1){
        return false;
      }
      // validar inputs instanciados en la vista
      /*let res2 = await this.$validator.validateAll();
      if(!res2){
        return false;
      } */

      // validar fg_reqs
      if(section.id == 38){
        let res3 = await this.$refs.family_group_reqs[0].checkValidation();
        if(!res3){
          return false;
        }
      }

      return true;
    },

    async validateFormFields(refGroup){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs[refGroup];
      let f;
      for(let p in grefs){
        f = grefs[p];
        let r = await f.checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          let sp = f.specialValidation();
          if(sp != null && sp.method in this){
            let res = await this[sp.method]();
            let n = f.rawFieldName;
            if(!res){
              allValid = false;
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
              continue;
            }
          }
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },

    async saveFormInfo(section){
      let ok = false;
      try{
        if(section.id == 38){
          let ff = this.$refs.family_group_reqs[0];
          await ff.saveFgReqs();
        }

        let payload = this.packNested(this.payload);
        if(this.location_id){
          payload.location_id = this.location_id;
        }
        await axios.post(`/api/v1/fg_locations/${this.fg_id}/updateFgLocation`, payload);
        if(this.addressEditFlag){
          this.reloadLocationInfo();
        }
        ok = true;
      }
      catch(e){
        this.warn(e);
        ok = false;
      }
      return ok;
    },

    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },
    evaluateCondition(condition){
      return this.evaluateConditionBase(this, condition);
    },

    /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'project'){
        return this.project.id;
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },

    processError(code){
      this.onError = true;
      this.errorCode = code;
    },
    setSectionGroups(){
      // this.addressSelectOptions.find(f => f.id == 2).valid = this.collections.existingLocations.length > 0;
    },

    /* on_change methods */
    async reload_cities(){
      try {
        let res = await axios.get('/api/register/cities/' + this.payload.address$state_id);
        this.collections.cities = res.data;
        // if(this.isMounted && !this.isRefreshing) this.payload.address$city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },

    async validate_fg_reqs(){
      return await this.$refs.family_group_reqs.validate();
    },

    setAddressToEdit(){
      this.addressEditFlag = true;
      this.payload.address_source = 3;
      this.locationDefined = false;

      this.setPayloadInformation(this.addressPayload);
    },

    setPayloadInformation(fgLocation){
      this.payload.address$city_id = fgLocation.city_id;
      this.payload.address$country_id = fgLocation.country_id;
      this.payload.address$ext_number = fgLocation.ext_number;
      this.payload.address$int_number = fgLocation.int_number;
      this.payload.address$municipality = fgLocation.municipality;
      this.payload.address$neighborhood = fgLocation.neighborhood;
      this.payload.address$other_city = fgLocation.other_city;
      this.payload.address$other_state = fgLocation.other_state;
      this.payload.address$state_id = fgLocation.state_id;
      this.payload.address$street = fgLocation.street;
      this.payload.address$zipcode = fgLocation.zipcode;
      this.payload.address$id = fgLocation.id

      this.reload_cities();
    },
    verify_zip_code_supplier_workplan_address() {
      this.verifyPayloadZipCode(this.payload, {
        addressName: 'address',
        statesArray: 'states_supplier_workplan_address', 
        citiesArray: 'cities_supplier_workplan_address', 
        neighborhoodsArray: 'neighborhoods_supplier_workplan_address',
        addressId: this.payload.address$id
      });
    },
    set_zip_code_values_supplier_workplan_address() {
      this.setPayloadZipCodeAddressValues(this.payload.address$id, this.payload, {
        addressName: 'address',
        statesArray: 'states_supplier_workplan_address', 
        citiesArray: 'cities_supplier_workplan_address', 
        neighborhoodsArray: 'neighborhoods_supplier_workplan_address'
      });
    }
  },
};
</script>

<style scoped>
.alt-radio-text {
  margin-left: 10px;
  color: rgba(var(--vs-success), 1);
}
</style>